import React from "react"
import { Link } from "gatsby"
// import { Nav } from "react-bootstrap"
import "./menu.css"

export default () => (
  <div className="navigation">
    <div className="nav-brand-side">
      <img className="img-fluid" src="/assets/img/logo.png" alt="logo" />
    </div>
    <ul className="list-unstyled main-nav mb-0">
      <li>
        <Link to="/">HOME</Link>
      </li>
      <li>
        <Link to="/about-us">ABOUT</Link>
      </li>
      <li>
        <Link to="/services">SERVICES</Link>
      </li>
      <li>
        <Link to="/projects">PROJECTS</Link>
      </li>
      <li>
        <Link to="/testimonials">TESTIMONIALS</Link>
      </li>
      <li>
        <Link to="/faq">FAQs</Link>
      </li>
      <li>
        <Link to="/blog">BLOG</Link>
      </li>
    </ul>
  </div>
)
