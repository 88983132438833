import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import "./header.css"

const Header = () => (
  <header>
    <Sticky
      className="sticky-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className="menu-container py-3">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-9 col-md-4">
              <div className="nav-brand">
                <Link to="/">
                  <img
                    className="img-fluid"
                    src="/assets/img/logo.png"
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-2 col-md-8 ml-auto">
              <SideBarMenu />
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
