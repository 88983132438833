import React from "react"
import { Link } from "gatsby"
import "./footer.css"
import WebriQForm from "../form/form"

const footer = props => {
  return (
    <div id="footer">
      <div className="contact-details">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d908.406488042623!2d-105.27604253406548!3d40.01954696209516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bec2851c7b2f5%3A0x89e6fe1fe8b29065!2s1526%20Spruce%20St%2C%20Boulder%2C%20CO%2080302%2C%20USA!5e0!3m2!1sen!2sph!4v1584517552600!5m2!1sen!2sph"
          width="100%"
          height="450"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          title="JKA Design Office Address"
        />
      </div>
      <div id="contact" className="contact-section padding-200">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto mb-5 pb-5 contact-clean">
              <WebriQForm
                form_name="Contact Us Form"
                form_id="5e8e8d1d5e411e116dc093b5"
                form_classname="contact-us"
              >
                <h2 className="text-center mt-0 mb-0 alt-heading">
                  CONTACT US
                </h2>
                <h2 className="text-center contact-heading mt-0">
                  Get in Touch
                </h2>
                <p className="text-center">
                  We would love to hear from you about your project–or just
                  answer your questions. Please message, email or call us and we
                  can talk about how JKA Design can help you.
                  <br />
                </p>
                <p className="text-center mb-5">
                  We're here to help create your dream a reality!
                </p>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Company"
                        placeholder="Company"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <textarea
                        className="form-control mb-2"
                        name="message"
                        placeholder="Message"
                        rows={14}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>
                  <div className="form-group w-100 px-1 mt-2">
                    <button className="btn-global offset2 w-100" type="submit">
                      send MESSAGE
                    </button>
                  </div>
                </div>
              </WebriQForm>
            </div>
          </div>
        </div>
      </div>

      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="mt-0 mb-4">
                QUICK LINKS
                <br />
              </h3>
              <div className="row">
                <div className="col-md-6">
                  <ul>
                    <li>
                      <Link to="/">HOME</Link>
                    </li>
                    <li>
                      <Link to="/about-us">ABOUT</Link>
                    </li>
                    <li>
                      <Link to="/services">SERVICES</Link>
                    </li>
                    <li>
                      <Link to="/projects">PROJECTS</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <Link to="/testimonials">TESTIMONIALS</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQs</Link>
                    </li>
                    <li>
                      <Link to="/blog">BLOG</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h3 className="mt-0 mb-0">
                JKA DESIGN LLC
                <br />
              </h3>
              <h6 className="mt-0 mb-4">Jeffrey K. Abrams, Architect</h6>
              <p>
                Our focus is the creative, client driven, design of each project
                and its execution. We are attuned to authenticity of character
                and style, and we are particularly adept at the challenges
                presented by the difficult and sometimes “unbuildable” site.
              </p>
            </div>
          </div>
          <hr className="pb-3" />
          <div className="row">
            <div className="col-md-3">
              <p>
                <i className="fa fa-map-o mr-2" /> 1526 Spruce Street Boulder,
                CO 80301
              </p>
            </div>
            <div className="col-md-3">
              <a href="mailto:jka@jkadesign.net">
                <i className="fa fa-envelope-o mr-2" /> JKA@JKADESIGN.NET
              </a>
            </div>
            <div className="col-md-3">
              <a href="tel:3034405497">
                <i className="fa fa-phone mr-2" /> 303.440.5497
              </a>
            </div>
            <div className="col-md-3">
              <span className="social-label mr-4">FOLLOW US:</span>{" "}
              <a
                href="https://www.linkedin.com/company/jka-design-llc"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-linkedin mr-4" />
              </a>
              <a
                href="https://www.facebook.com/JKADesign/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-facebook" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container-fluid footer-section">
          <div className="text-center text-white">
            <div className="row">
              <p className="mb-0 mx-auto">
                <sup>©</sup> 2020 JKA Design LLC. All Rights Reserved. Designed
                And Powered By 
                <a href="https://www.webriq.com/" rel="noopener noreferrer">
                  <span>WebriQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default footer
